import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Menu as MenuIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Stack,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import LanguagesMenu from "../../../components/LanguagesMenu/LanguagesMenu";
import theme from "../../../components/theme/theme";
import logo from "../../../LOGO.png";
import "./Nav.css";
import LoginMenu from "../../../components/LoginMenu/LoginMenu";
import logoTextImage from "../../../LOGO-TEXT.png";
import useUser from "../../../context/User";
import { Link } from "react-router-dom";
/* https://mui.com/material-ui/react-app-bar/ */
const drawerWidth = 240;
//  const pages = [ "employer", "worker", "about_us", "contact_us"];
const pages = ["employer", "worker", "contact_us"];


function Nav(props) {
  const { login, currentUser, logout } = useUser();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigation = (item) => {
    navigate(`/${item}`);
  };

  const LogoImg = () => {
    return (
      <Box
        component="img"
        alt="Logo of Agence-D-Agency inc. "
        className=" nav__logo"
        src={logo}
        sx={{
          width: "2em",
          height: "100%",
          maxWidth: { xs: 40, md: 165 },
          minWidth: { xs: "4rem", md: "5rem" },
          ml: { md: -3, xs: -2 },
          flexGrow: 1,
        }}
      />
    );
  };

  // ***********   Hidden Menu for Small Screens **********//
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          backgroundColor: "primary.main",
        }}
      >
        <Link to="/" color="inherit" underline="none">
          <img
            alt="Logo of Agence-D-Agency inc. "
            src={logoTextImage}
            style={{
              width: "13em",
              height: "auto",
              maxWidth: { xs: 100, md: 170 },
              minWidth: { xs: "13em", md: "20em" },
              flexGrow: 1,
              margin: "auto",
            }}
          />
        </Link>
      </Box>
      <Divider />

      <List>
        {pages.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center", textTransform: "uppercase" }}
              onClick={() => handleNavigation(item)}
            >
              <ListItemText primary={t(`block.header.nav.${item}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  // ***********   End Hidden Menu for Small Screens **********//

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{ background: `${theme.palette.gradient.main}` }}
      >
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            sx={{ justifyContent: "space-between", gap: "15px" }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {/* ------ Logo img------ */}

            <Link
              to="/"
              color="inherit"
              underline="none"
              className="nav__logoLink"
            >
              {LogoImg()}
            </Link>

            {/*  ------- Nav links-------- */}
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: {
                  xs: "none",
                  sm: "flex",
                },

                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >

              {pages.map((page) => (

                <Button
                  key={page}
                  color='inherit'
                  sx={{ my: 2, color: "light.main", display: "block" }}
                  className='nav__links'
                  onClick={() => { handleNavigation(page); }} >
                  <div></div>
                  {t(`block.header.nav.${page}`)}
                </Button>

              ))}

            </Box>

            {/*  ------- Language and login links -------- */}
            <Stack
              direction="row"
              spacing={{ xs: 0, sm: 1, md: 2, flexGrow: 1 }}
              alignItems="center"
              className="nav__acces"
            >
              <LanguagesMenu />

              {/* {!currentUser ?  
              <Button variant="text" sx={{ color: "light.main",}} onClick={() => { login("toto@magicien.ca", "magic"); }}>
                Login
                </Button> : <LoginMenu />} */}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}

export default Nav;
