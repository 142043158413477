import React from 'react';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Box, Container, Grid, Link, Typography, IconButton, ThemeProvider, CssBaseline, List, ListItem, Button } from '@mui/material';
import { useNavigate } from "react-router";
import theme from "../../components/theme/theme";
import SocialMedias from '../../components/SocialMedias/SocialMedias';

const pages = ["home", "employer", "worker", "about_us"];

export default function Footer() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigation = (item) => {
    navigate(`/${item === "home" ? "" : item}`);
  };

  const socialsData = SocialMedias();


  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        component="footer"
        py={6}
        px={1}
        backgroundColor='primary.main'
        color='light.main'
        sx={{
          backgroundColor: 'primary.main',
        }}
      >
        <Container maxWidth="lg" >
          <Grid container spacing={3} gap={1} justifyContent="space-between">


            <Grid item xs={12} md={3}>
              <Box
                display="flex"
                alignItems={{ xs: "center", md: "flex-start" }}
                flexDirection='column'
                flexWrap='wrap'
                gap={3}
                mt={.5}>

                <img src="/LogosSuperposeText.svg" alt="Logo Agence D" width={190} height={190} />

              </Box>
            </Grid>


            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, flexDirection: 'column', }} alignItems={{ xs: "center", md: "flex-start" }}>

                <Typography variant="h6" textTransform='Uppercase' > {t("block.footer.site_links")}</Typography>

                <List>

                  {pages.map((pageLink) => (
                    <ListItem key={pageLink} disablePadding>
                      <Link
                        component="button"
                        variant="body1"
                        color='link.primary'
                        underline="none"
                        sx={{
                          lineHeight: 1.3,
                          '&:hover': {
                            color: 'link.hover',
                          },
                        }}
                        onClick={() => handleNavigation(pageLink)}
                      >

                        {t(`block.header.nav.${pageLink}`)}

                      </Link>
                    </ListItem>
                  ))}

                </List>

              </Box>
            </Grid>


            <Grid item xs={12} sm={6}
              md={4} lg={4}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, flexDirection: 'column' }} alignItems={{ xs: "center", md: "flex-start" }}>

                <Typography variant="h6" textTransform='Uppercase'>
                  {t("block.footer.title")}
                </Typography>

                <Box gap={3} sx={{ display: 'flex', alignItems: { xs: "center", md: "flex-start", }, flexDirection: 'column' }}>

                  <Typography variant="p" >
                    {t("block.footer.description")}
                  </Typography>
                  <Box mt={1} display='flex' alignItems='center' gap={2} flexWrap='wrap' justifyContent={{ xs: "center", md: "flex-start" }} >

                    <Box>
                      <Button
                        variant="contained"
                        component={motion.button}
                        whileHover={{ transform: 'translateY(-0.2rem)' }}
                        animate={{ opacity: 1, duration: 2000, }}
                        className="btn accent"
                        color="accent"
                        onClick={() => { handleNavigation("contact_us"); }}
                      >

                        <>{t("shared.btn.contact_us")}</>

                      </Button>
                    </Box>

                    <Box ml={{ xs: 0, sm: 1 }}>

                      {socialsData.map((socialLink) => (
                        socialLink.link && (
                          <IconButton
                            component={motion.a}
                            whileHover={{ transform: 'translateY(-0.2rem)' }}
                            animate={{ opacity: 1, duration: 2000, }}
                            href={socialLink.link}
                            target='_blank'
                            key={socialLink.id}
                            aria-label={`${socialLink.name} " icon link"`}
                            sx={{
                              fontSize: '1.8rem',
                              color: 'link.primary',
                              '&:hover': {
                                color: '#f1896e',
                              },
                            }}
                          >
                            {socialLink.icon}
                          </IconButton>
                        )
                      ))}

                    </Box>

                  </Box>
                </Box>
              </Box>
            </Grid>


          </Grid>


          <Box mt={6} textAlign="center" color='light.main'>
            <Typography variant="body1" fontSize={14} >
              © Agence-D-Agency {new Date().getFullYear()}. {t("block.footer.credit")}
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider >
  );
}
