import { useTranslation } from "react-i18next";
import { Box, Container, Typography, AccordionDetails, Accordion, AccordionSummary, MenuItem, Link, Button, Card, CardContent, Paper } from "@mui/material";
import { motion } from "framer-motion";
import splitTitle from "../../util/Traits/SplitTitle";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import "../Header/Nav/Nav.css"; /* For hover on link in the summary */


export default function Introduction({ page, data }) {

    const { t } = useTranslation();
    const [titleWords, lastWord] = splitTitle(t(`block.introduction_${page}.title`))

    return (
        <Container maxWidth="lg" style={{ margin: "6rem  auto 3rem auto", }}>
            <Container
                component={motion.div}
                whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
                transition={{ duration: 0.5 }}
                maxWidth="lg"
                sx={{
                    textAlign: "center",
                    mb: "3rem"
                }}
            >
                <Box
                    sx={{
                        textAlign: "center",
                        mb: "3rem"
                    }}>
                    {/* display the Accordion if page is not contact */}
                    {page !== "contact" ? (
                        <Accordion
                            square={true}
                            sx={{
                                border: "none",
                                boxShadow: "none",
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardArrowUpIcon sx={{ fontSize: '2rem' }} />}
                                aria-controls="H1-content"
                                id="H1-header"
                                sx={{ display: "inline-flex", width: "auto" }}
                            >
                                <Typography
                                    align="center"
                                    variant="h3"
                                    component="h2"
                                    gutterBottom
                                    color="textPrimary.main"
                                    sx={{
                                        width: '100%',
                                        fontWeight: "bold",
                                        "& span:last-child": {
                                            color: "secondary.main",
                                        },
                                        textTransform: "capitalize",
                                        margin: 0
                                    }}
                                >
                                    {titleWords.join(" ")} <span>{lastWord}</span>
                                </Typography>
                            </AccordionSummary>
                            < Paper elevation={4} sx={{ paddingTop: "30px", backgroundColor: "#eff2f9", width: "60%", margin: "0 auto", }} >
                                <CardContent sx={{ paddingY: "0px", }}>
                                    <AccordionDetails sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 2 }}>

                                        {data?.map((service) => (
                                            <Link className="nav__links" href={`#service.${page}.${service?.id}.title`} key={service.id} value={service.id} id={service.id} sx={{ textDecoration: "none" }}>
                                                <Button
                                                    key={service.id}
                                                    id={service.id}
                                                    variant="outlined"
                                                    component={motion.button}
                                                    whileHover={{ transform: 'translateY(-0.2rem)' }}
                                                    animate={{ opacity: 1, duration: 2000, }}
                                                    className="btn accent"
                                                    sx={{ color: "textPrimary.main", backgroundColor: "white" }}
                                                >

                                                    <>  {t(`service.${page}.${service?.id}.title`)}</>

                                                </Button>
                                            </Link>

                                        ))}
                                    </AccordionDetails>
                                </CardContent>
                            </Paper>
                        </Accordion>
                    ) : (
                        <Typography
                            align="center"
                            variant="h3"
                            component="h2"
                            gutterBottom
                            color="textPrimary.main"
                            sx={{
                                width: '100%',
                                fontWeight: "bold",
                                "& span:last-child": {
                                    color: "secondary.main",
                                },
                                textTransform: "capitalize",
                            }}
                        >
                            {titleWords.join(" ")} <span>{lastWord}</span>
                        </Typography>
                    )}

                    <Typography component="p" color="textPrimary.main" pt="30px">
                        {t(`block.introduction_${page}.description`)}<br />
                    </Typography>
                </Box>


            </Container>
        </Container>

    )
}